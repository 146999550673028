@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root1 {
    background-color: #fff;
    @media (max-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    
}
.total_container {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div {
    flex: 1 0 auto;
}
.foot_img_div {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img {
    height: 149px;
    width: 100%;
    /* background-image: url("assets/houses2.webp"); */
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div {
  padding: 25px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result {
  padding: 15px 0px;
  /* border-bottom: 1px solid rgb(230,230,230); */
}
.content_head {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div {
    cursor: pointer;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div {
    width: 115px;
}
.content_quiz_div {
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.result_div_font {
    font-family: "Roboto";
}
.quiz_div_fadeOut {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn {
  opacity: 1;
  transition: opacity 1.5s;
  
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
.content_quiz_container {
    margin-top: 60px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 15px;
}
.quiz_question_div {
    font-size: 40px!important;
    line-height: 44px!important;
    margin-bottom: 50px;
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}
.quiz_answers_button_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    min-height: 190px;
    transition: transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
}
.quiz_answers_button_div:hover {
    transform: translate(0px, -5%);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span {
    background-image: url("assets/yes.svg");
    background-size: 70%;
    margin: 20px auto 0px;
    display: block;
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span {
    /* height: 55px; */
    margin: 5px;
    line-height: 22px;
    /* font-size: 17px; */
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    text-align: center;
    display: block;
}

.quiz-container1 {
    @media (min-width: 800px) {
      width: 800px;
      min-width: 250px;
      background: #ffffff;
      border-radius: 4px;
      margin: 50px auto;
      padding: 100px 60px;
      display: flex;
      flex-direction: column;
      background: transparent;
    }
  
    background: #ffffff;
    border-radius: 4px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  .quiz-container1 h2 {
    /* text-align: center; */
    @media (min-width: 800px) {
      font-size: 1.8rem;
      color: #161a1f;
      line-height: 1.4;
      font-weight: 500;
      margin: 0 0 0 0;
    }
  
    font-size: 1.5rem;
    color: #161a1f;
    line-height: 1.3;
    font-weight: 500;
    margin: 0 0 0 0;
  }
  
  .quiz-container1 h4 {
    @media (min-width: 800px) {
      font-size: 1.6rem;
      color: #161a1f9c;
      line-height: 1.4;
      font-weight: 500;
      margin: 10px 0 0 0;
    }
    font-size: 1.3rem;
    color: #161a1f9c;
    line-height: 1.2;
    font-weight: 500;
    margin: 10px 0 0 0;
  }

  .quiz-container1 ul {
    margin-top: 20px;
    margin-left: -40px;
  }

  
.quiz-container1 ul li {
    @media (min-width: 800px) {
      font-size: 1.3rem;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    /* font-size: 16px; */
    /* font-size: 1.3rem; */
    /* background: #ffffff; */
    background: #8ebaf133;
    /* border: 1px solid #eaeaea; */
    border-radius: 5px;
    padding: 25px 25px;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 350ms ease-in-out;
  }
  
  .quiz-container1 ul li .txt_index {
    border: 2px solid #0571f782;
    padding: 8px 12px;
    line-height: 1;
    text-align: center;
    border-radius: 5px;
    background: white;
  }

  .quiz-container1 ul li:hover {
    border: 1px solid #0089ff;
  }

  .quiz-container1 ul .selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
  }

  .quiz-container1 button {
    background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }

  .quiz-container1 button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
  }

  .quiz-container1 .choice_div {
    @media (min-width: 800px) {
      width: 500px;
      /* width: 100%; */
      max-width: 500px;
      margin: 45px 0 0 0;
    }
    /* width: 500px; */
    /* width: 100%; */
    max-width: 500px;
    margin: 0 0 0 0;
  }
  