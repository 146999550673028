.top_wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    margin: 0;
}

.ocm_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    height: auto;
}

.ajax_content_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.container_wrapper {
    padding: 0px;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
}