.sub_02_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
}

.sub_02_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    margin-top: 100px;
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px;
    }

} 

.sub_02_row_col_wrap_12_col {
    margin: 0;
    padding: 0;
    border: 0;

    margin-left: -20px;
    margin-right: -20px;

    display: flex;    
    flex-wrap: wrap;
    float: none;

    margin-bottom: 0!important;
    z-index: 10;
    width: auto;

    position: relative;
    box-sizing: border-box;
}

.sub_02_vc_col_sm_12 {
    padding: 0;
    border: 0;

    margin-top: 50px;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    background-position: center;

    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;

}

.sub_02_vc_col_sm_12 h2 {
    margin: 0;
    padding: 0;
    border: 0;

    font-family: 'RobotoBold';
    letter-spacing: -1px;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 7px;

    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 35px;
        line-height: 42px;
    }
}

.sub_02_wpb_row_vc_row_fluid {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    padding-bottom: calc(100vw * 0.07);
    margin-bottom: 0;
    box-sizing: border-box;
    background-size: cover;
    width: 100%;
    position: relative;

    @media (max-width: 800px) {
        padding: 0px
    }
}


.sub_02_vc_column_inner {
    margin: 0;
    padding: 0;
    border: 0;

    padding: calc(1245px * 0.01);
    position: relative;

    box-sizing: border-box;

    text-align: center;

}

.sub_02_wpb_wrapper_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_row_grid {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 40px;
    position: relative;
    box-sizing: border-box;
}

.sub_02_row_col_wrap_12_inner_grid {
    margin: 0;
    padding: 0;
    border: 0;

    margin-left: -20px;
    margin-right: -20px;

    margin-bottom: 0!important;

    display: flex;
    flex-wrap: wrap;
    float: none;

    z-index: 10;

    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_vc_col_sm_12_grid {
    margin: 0;
    padding: 0;
    border: 0;
    transform: none;
    opacity: 1;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    transition: opacity .6s cubic-bezier(.15,.84,.35,1.25), background-color .35s cubic-bezier(.65,0,.35,1);
    position: relative;
    box-shadow: none;
    background-position: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_nectar_icon_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_nectar_icon_grid {
    margin: 0;
    border: 2px solid rgba(0, 0, 0, 0.065);

    border-color: #ff5433;
    border-width: 2px;
    padding: 25px;
    line-height: 0;
    text-align: center;
    border-radius: 150px;
    position: relative;
    transition: background-color .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1);
    box-sizing: border-box;

}

.sub_02_im_icon_wrap_grid {
    margin: 0;
    padding: 0;
    border: 0;

    height: 60px;
    width: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_title_row_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 40px;
}

.sub_02_vc_col_sm_12_title_grid {
    margin: 0;
    padding: 0;
    border: 0;
    transform: translateY(0px) translateZ(0px);
    opacity: 1;
    margin-left: 0;
    float: none;
    margin-right: 0;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    box-shadow: none;
    background-position: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;

}

.sub_02_wpb_text_column_grid {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 315px;
    display: inline-block;
    margin-bottom: 0;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_column_grid h4 {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Roboto';
    font-weight: 500;
    margin-bottom: 8px;
    color: #000000;
    letter-spacing: 0;
    font-size: 18px;
    line-height: 32px;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_row_text_grid {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 0;
    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_col_sm_12_inner_grid {
    margin: 0;
    padding: 0;
    border: 0;
    transform: none;
    opacity: 1;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    box-shadow: none;
    background-position: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_col_grid {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 315px;
    display: inline-block;
    margin-bottom: 0;
    margin-top: -20px !important;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_col_grid p {
    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 1.5em;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_col_grid p span {
    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 1.5em;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}
