.sub_04_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
}

.sub_04_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    margin-top: 100px;
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px
    }

} 

.sub_04_row_col_wrap_12_col {
    margin: 0;
    padding: 0;
    border: 0;

    margin-left: -20px;
    margin-right: -20px;

    display: flex;    
    flex-wrap: wrap;
    float: none;

    margin-bottom: 0!important;
    z-index: 10;
    width: auto;

    position: relative;
    box-sizing: border-box;
}

.sub_04_vc_col_sm_12 {
    padding: 0;
    border: 0;

    margin-top: 50px;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    background-position: center;

    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;

}

.sub_04_vc_col_sm_12 h2 {
    margin: 0;
    padding: 0;
    border: 0;

    font-family: 'RobotoCondensedSemiBold';
    letter-spacing: -1px;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 7px;

    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 35px;
        line-height: 42px;
    }
}

.sub_04_wpb_row_vc_row_fluid {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    /* padding-bottom: calc(100vw * 0.07); */
    padding-bottom: 40px;
    margin-bottom: 0;
    box-sizing: border-box;
    background-size: cover;
    width: 100%;
    position: relative;
    @media (max-width: 800px) {
        padding: 0;
    }
}


.sub_04_vc_column_inner {
    margin: 0;
    padding: 0;
    border: 0;

    padding: calc(1245px * 0.01);
    position: relative;

    box-sizing: border-box;

    text-align: center;

}

.sub_04_wpb_wrapper_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    text-align: center;
}

.sub_04_nectar_star_rating {
    margin: 0;
    padding: 0;
    border: 0;

    font-size: 20px;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
}

.sub_04_star_rating_icons {
    margin: 0;
    padding: 0;
    border: 0;

    font-size: inherit;
    line-height: 1.3;
    color: #ff5433;

    box-sizing: border-box;

}

.sub_04_rate {
    float: left;
    /* height: 46px;
    padding: 0 10px; */
}
.sub_04_rate > label {
    float: left;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 20px;
    color: #ccc;
    margin-right: 8px;
}

.sub_04_rate > label:before {
    content: '★ ';
    color: #ff5433;
    font-size:25px;
}

.sub_04_img_with_animation_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 0;
    margin-bottom: 0;
    box-sizing: border-box;
}

.sub_04_inner_rating {
    margin: 0;
    padding: 0;
    border: 0;
    height: 100%;
    box-sizing: border-box;
}

.sub_04_hover_wrap_rating {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    overflow: visible;
    opacity: 1;
    margin-bottom: 15px;
    position: relative;
   
}

.sub_04_hover_wrap_inner_rating {
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.sub_04_hover_wrap_inner_rating img {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    opacity: 1;
    transform: none;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    box-shadow: none;
    max-width: 100%;
    height: auto;
    position: relative;
}

.sub_04_wpb_text_column_rating {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-bottom: 0;
}

.sub_04_wpb_text_column_rating p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: left;
}

.sub_04_wpb_text_column_rating p span {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-bottom: 0;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}