.pfs_main_container {
    padding-bottom: .75rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-top: .75rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.pfs_main_wrapper {
    flex-grow: 1;
    max-width: 1150px;

    @media (max-width: 800px) {
        width: 100%;
    }
}

.pfs_rounded {
    box-shadow:  0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a,0 1px 2px -1px #0000001a;
    background-color: #ffffff;
    border-radius: .25rem;
    color: #131310;
}

.pfs_flex_py_3 {
    border-color: #efeeea;
    border-top-style: solid;
    border-bottom-width: 0;
    border-top-width: 1px;
    padding-bottom: .75rem;
    padding-top: .75rem;
    display: flex;

    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.pfs_p_3_w_2_3 {
    padding: .75rem;
    gap: .75rem;
    align-items: flex-start;
    width: 66.6666667%;
    display: flex;

    @media (max-width: 800px) {
        width: 93%;
        flex-direction: column;
    }
}

.pfs_p_3_w_1_3 {
    padding: .75rem;
    border-color: #efeeea;
    border-left-width: 1px;
    border-left-style: solid;
    width: 33.3333333%;

    @media (max-width: 800px) {
        width: 93%;
        flex-direction: column;
    }
}

.pfs_w_auto {
    width: auto;
}

.pfs_w_52px {
    background-size: cover;
    border-radius: 9999px;
    overflow: hidden;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    width: 52px;
    height: 52px;
    display: flex;
}

.pfs_w_52px > img {
    height: auto;
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

.pfs_grow_w_676 {
    flex-grow: 1;
    width: 676.66px;

    @media (max-width: 800px) {
        width: 95%;
    }
}

.pfs_w_full_flex {
    gap: .75rem;
    align-items: center;
    width: 100%;
    display: flex;
    margin-bottom: .75rem;

}

.pfs_font_semibold {
    font-weight: 600;
}

.pfs_cursor_pointer {
    cursor: pointer;
}

.pfs_border_1 {
    padding-top: 1rem;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    border-left-width: 1px;
}

.pfs_text_xs {
    font-size: .75rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
}

.pfs_text_icon {
    font-weight: 300;
    font-size: .875em;
    line-height: .07143em;
    vertical-align: .05357em;
    font-family: "Font Awesome 6 Sharp";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: #acaca9;
}

.pfs_text_8B8B88 {
    color: #8b8b88;
    word-break: break-word;
}

.pfs_flex_tems_start {
    font-size: .875rem;
    line-height: 1.25rem;
    gap: .5rem;
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem;
}

.pfs_flex_justify_center_item {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a,0 1px 2px -1px #0000001a;
    background-color: #f7f6f4;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    display: flex;
}



.pfs_brief_icon {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_font_medium_des {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    max-width: 95%;
    word-break: break-word;

    @media (max-width: 800px) {
        flex-direction: column!important;
    }
}

.pfs_font_medium_activity {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    max-width: 95%;
    word-break: break-word;
}

.pfs_mobile_none {
    @media (max-width: 800px) {
        display: none;
    }
}

.pfs_desktop_none {
    @media (min-width: 800px) {
        display: none;
    }
}

.pfs_flex_items_center_gap_2 {
    font-size: .875rem;
    line-height: 1.25rem;
    gap: .5rem;
    /* align-items: center; */
    display: flex;
    margin: 5px 0;
    
}

.pfs_buildings_icon {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_flex_items_start_gap {
    font-size: .875rem;
    line-height: 1.25rem;
    gap: .5rem;
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem;
}

.pfs_envelope {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_font_medium_text_xs {
    font-weight: 500;
    font-size: .75rem;
    line-height: 1.25rem;
}

.pfs_binoculars {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_word_break {
    word-break: break-word;
}

.pfs_word_break_normal {
    word-break: normal;
}