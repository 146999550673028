.sameline_title_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quiz_content_item_div {
    border: 1px solid #c0c2c3a3;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
    transition: all 150ms ease-in-out;
    margin-bottom: 5px!important;
}

.quiz_content_item_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
}

.quiz_content_item_title {
    /* font-size: 16px; */
    font-weight: 600;
}

.quiz_content_item_text {
    overflow-wrap: anywhere;
}

.quiz_child_div {
    padding: 10px 15px!important;
    border: 1px #ebe7e7 solid!important;
    border-radius: 5px!important;
    margin-bottom: 10px;
    transition: all 150ms ease-in-out;
}

.quiz_child_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
 }