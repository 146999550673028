.sub_08_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
    background-color: #fafafa;
}

.sub_08_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    /* margin-top: 100px; */
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0;
    }

} 

.sub_08_wpb_row_vc_fluid {
    margin: 0;
    padding: 0;
    border: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    /* margin-bottom: 150px; */
    /* margin-top: 150px; */
    background-size: cover;
    width: 100%;
    position: relative;
    box-sizing: border-box;

}

.sub_08_row_col_wrap_12 {
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0!important;
    float: none;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
    width: auto;
    position: relative;
    box-sizing: border-box;
}

.sub_08_vc_col_sm_12 {
    
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    box-shadow: none;
    background-position: center;
    position: relative;
    width: 100%;
    display: block;
}

.sub_08_wpb_text_column {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;

}

.sub_08_wpb_text_column p {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;

    font-family: 'RobotoCondensedRegular';
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -1px;
}

.sub_08_img_with_animation {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 0;
    margin-bottom: 0;
    box-sizing: border-box;

}

.sub_08_inner {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_08_inner img {
   

    @media (max-width: 800px) {
        
            height: 22px;
    }
}

.sub_08_hover_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 15px;
    opacity: 1;
    overflow: visible;
}

.sub_08_hover_wrap_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
}

