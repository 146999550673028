.rvp_talk_advisor_container {
    width: 100%;
    max-width: 760px;
    line-height: 1.4;
    color: #000;
}

.rvp_talk_advisor_flex {
    grid-column-gap: 1.5rem;
    grid-row-gap: 0rem;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, .3fr) minmax(0, 1.55fr);
    grid-auto-columns: 1fr;
    align-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: .75rem;
    display: grid;

    @media screen and (max-width: 800px) {
        grid-column-gap: 10px;
        padding: 0 20px;
    }
}

.rvp_advisor_photo {
    grid-area: span 2 / span 1 / span 2 / span 1;
    width: 100%;
    position: relative;
}

.rvp_ssr_talk_advisor_photo {
    z-index: 5;
    border: 2px solid #fff;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
}

.rvp_ssr_image_cover_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;

    background-color: transparent;
    max-width: 100%;
    display: inline-block;

    vertical-align: middle;
    background: #9ba0a8;

    display: flex;
    justify-content: center;
    align-items: center;
}

.rvp_ssr_talk_advisor_photo_shadow {
    background-color: #0266fd;
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
    transform: translate(-7px, -7px);
}

.rvp_advisor_title {
    align-self: end;
    box-sizing: border-box;
    text-align: start;

    @media screen and (max-width: 800px) {
        order: -9999;
        grid-area: span 1 / span 2 / span 1 / span 2;
    }
}

.rvp_ssr_h1 {
    margin-bottom: 8px;
    font-size: 26px;
    font-weight: 700;
    margin-top: 0;
    line-height: 1.3;
    color: #00162a;
}

.rvp_advisor_info {
    align-self: start;
}

.rvp_ssr_talk_advisor_checklist {
    grid-column-gap: .25rem;
    grid-row-gap: .25rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-left: 10px;
    display: grid;

    box-sizing: border-box;
}

.rvp_ssr_talk_advisor_checkitem {
    background-image: url(https://cdn.prod.website-files.com/5dbc34a79684004f6e7a6c30/6279e57ed5cd4f5cf6049f14_green_check_checklist_mark_icon.svg);
    background-position: 0 4px;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 22px;
    font-size: 16px;
    font-weight: 700;
    text-align: start;

    box-sizing: border-box;
}

.rvp_ssr_talk_advisor_p {
    margin-bottom: 12px;
    line-height: 1.4;
    color: #3d475c;
    font-size: 18px;
    margin-top: 0;
}

.rvp_content_container {
    width: 100%;
    max-width: 760px;
    margin-top: 40px;
    line-height: 1.4;
    color: #000;
}

.rvp_question_container {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
}

.rvp_question_col {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.rvp_col_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.rvp_col_ques_title {
    font-size: 1.5rem;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
        text-align: left;
    }
}

.rvp_col_sub_str {
    font-size: 0.8125rem;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
        text-align: left;
    }
}

.rvp_pl_5_mr_1 {
    /* padding-left: 1.25rem; */
    box-sizing: border-box;
    width: 49%;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.rvp_field_wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;
}

.rvp_flex_col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_col_label_div {
    display: flex;
    width: 100%;
    font-weight: 500;
    margin-bottom: 9px;
}

.rvp_input_field_div {
    position: relative;
    width: 100%;
    margin-bottom: 9px;
}

.rvp_input_field_text {
    border-width: 1px;
    border-color: rgba(75, 85, 99, 0.2);
    /* background: rgb(255, 255, 255); */
    background-color: transparent;
    color: rgb(75, 85, 99);
    font-size: 0.9375rem;
    line-height: 1.2em;
    min-height: 2.375rem;
    touch-action: manipulation;    
    border-radius: .25rem;
    width: 100%;
    padding: .5rem .75rem;
    margin: 0;
    box-sizing: border-box;
}

.rvp_input_field_text_email {
    border-width: 1px;
    border-color: rgba(75, 85, 99, 0.2);
    /* background: rgb(255, 255, 255); */
    background-color: transparent;
    color: rgb(75, 85, 99);
    font-size: 0.9375rem;
    line-height: 1.2em;
    min-height: 2.375rem;
    touch-action: manipulation;    
    border-radius: .25rem;
    width: 100%;
    padding: .5rem .75rem;
    padding-left: 2.5rem;
    margin: 0;
    box-sizing: border-box;
}

.rvp_input_field_text_email::placeholder {
    color: #c1c4dc;
}

.rvp_absolute_mail_svg {
    position: absolute;
    padding-left: .75rem;
    align-items: center;
    display: flex;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    box-sizing: border-box;
}

.rvp_required_svg {
    width: 1rem;
    height: 1rem;
}

.rvp_btn_div {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;
}

.rvp_btn_flex_div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
}

.rvp_btn_btn {
    display: inline-flex;
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-color: transparent;
    border-width: 1px;
    border-radius: .375rem;
    justify-content: center;
    align-items: center;
    text-transform: none;
    overflow: visible;
    margin: 0;
    touch-action: manipulation;
    cursor: pointer;
    box-sizing: border-box;
}

.rvp_btn_btn:hover {
   transform: none;
   background: rgb(88, 149, 247) !important;;
}

.rvp_ant_space {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_ant_space_item {
    box-sizing: border-box;
}

.rvp_thanks_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}

.rvp_thanks_container {
    /* background: #fff; */
    background-color: transparent;
    max-width: 650px;
    border-radius: .5rem;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    position: relative;
    box-sizing: border-box;
}

.rvp_thanks_check_div_wrapper {
    margin-bottom: .75rem;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.rvp_thanks_check_container {
    width: 48px;
    height: 48px;
    background: #3b82f61a;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;
}

.rvp_thanks_str_div_wrapper {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
}

.rvp_thanks_str_title_div {
    color: #374151;
    box-sizing: border-box;
}

.rvp_thanks_str_title_wrapper {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: center;
}

.rvp_thanks_str_title_p {
    font-size: 1.875rem;
    line-height: 1.3em;
    font-weight: 700;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

}

.rvp_thanks_str_substr_div {
    margin-top: 3px;
    box-sizing: border-box;
    color: #9ba0a8;
    text-align: center;
}

.rvp_thanks_str_substr_p {
    font-size: 0.9375rem;
    line-height: 1.3em;
    font-weight: 400;
    margin-bottom: 0px;
    color: #9ba0a8;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rvp_circle_progress_div_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}

