.sub_07_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);

    background-color: #f7f7f7;
}

.sub_07_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0;

    }

} 

.sub_07_wpb_row_vc_fluid {
    margin: 0;
    padding: 0;
    border: 0;
   
    position: relative;
    box-sizing: border-box;
}

.sub_07_row_col_wrap_12 {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-wrap: wrap;
    float: none;
    margin-bottom: 0!important;
    z-index: 10;
    width: auto;
    position: relative;
}

.sub_07_vc_col_sm_12 {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;

    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    background-position: center;
    width: 100%;

}

.sub_07_vc_row_fluid_ft {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0;
    position: relative;
    background-size: cover;
    width: 100%;
}

.sub_07_wpb_wrapper {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_07_img_with_animation {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    line-height: 0;
    margin-bottom: 0;
}

.sub_07_inner_img_div {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    height: 100%;
}

.sub_07_hover_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    overflow: visible;
    opacity: 1;
    margin-bottom: 15px;
    position: relative;

    @media (max-width: 800px) {
        margin-bottom: 35px;
    }
}

.sub_07_hover_wrap_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    transition: transform .65s cubic-bezier(.05,.2,.1,1);
}

.sub_07_hover_wrap_inner img {
    @media (max-width: 800px) {
        max-width: 100%;
        height: auto;

    }
}

.sub_07_nectar_split_heading {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-bottom: 24px;
}

.sub_07_nectar_split_heading p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    line-height: 32px;
}

.sub_07_nectar_split_heading p span {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    line-height: 1.2;
    vertical-align: bottom;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.sub_07_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    position: relative;
    display: inline-block;

    line-height: 1.2;

    vertical-align: bottom;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_07_wpb_text_column_ft {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_07_wpb_text_column_ft p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-bottom: 1.5em;
}

.sub_07_wpb_text_column_ft p a {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    color: #ff5433;
    text-decoration: none;
    transition: color .3s;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_07_professor_prebid_root {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-bottom: 24px;
}

.sub_07_wpb_wrapper_02 {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_07_wpb_wrapper_02 p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-bottom: 1.5em;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_07_wpb_wrapper_02 p a {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    color: #ff5433;
    text-decoration: none;
    transition: color .3s;
}