@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Montserrat', sans-serif;
}

.top_wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    margin: 0;
}

.ocm_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    height: auto;
}

.ajax_content_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.container_wrapper {
    padding: 0px;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
}
.sub_01_wpb_row {
    margin: 0;
    padding: 0;
}

.sub_01_row_col_wrap_12 {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    float: none;
    z-index: 12;
    width: auto;
    border: 0;
    position: relative;
    box-sizing: border-box;
}

.sub_01_vc_col_sm_12 {
    display: flex;
    margin: 0;
    padding: 0;
    float: none;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    position: relative;
    box-sizing: border-box;
}

.sub_01_vc_column_inner {
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: calc(100vw * 0.05);
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
}

.sub_01_column_image_bg_wrap {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: opacity .55s ease-out .2s, -webkit-transform 1.3s cubic-bezier(.12,.75,.4,1);
    transition: transform 1.3s cubic-bezier(.12,.75,.4,1), opacity .55s ease-out .2s;
    transition: transform 1.3s cubic-bezier(.12,.75,.4,1), opacity .55s ease-out .2s, -webkit-transform 1.3s cubic-bezier(.12,.75,.4,1);
    z-index: -1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_01_inner_wrap {
    -webkit-transform: scale(1);
            transform: scale(1);
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transition: -webkit-transform 1.3s cubic-bezier(.12,.75,.4,1);
    transition: transform 1.3s cubic-bezier(.12,.75,.4,1);
    transition: transform 1.3s cubic-bezier(.12,.75,.4,1), -webkit-transform 1.3s cubic-bezier(.12,.75,.4,1);
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.cub_01_column_image_bg {
    /* background-image: url('assets/headliess_cms2_scaled.jpg'); */
    background-image: url(/static/media/headliess_cms2_scaled_02.83482f6f.jpg);
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

.sub_01_column_bg_overlay {
    overflow: hidden;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: background-color .45s cubic-bezier(.25,1,.33,1), opacity .35s ease-out, -webkit-transform 1.3s cubic-bezier(.12,.75,.4,1);
    transition: background-color .45s cubic-bezier(.25,1,.33,1), opacity .35s ease-out, transform 1.3s cubic-bezier(.12,.75,.4,1);
    transition: background-color .45s cubic-bezier(.25,1,.33,1), opacity .35s ease-out, transform 1.3s cubic-bezier(.12,.75,.4,1), -webkit-transform 1.3s cubic-bezier(.12,.75,.4,1);
    pointer-events: none;
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: ' ';
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_01_column_overlay_layer {
    background-color: rgba(10, 10, 10, 0.59);
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: ' ';
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_wpb_wrapper {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_vc_column_inner_grid {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_wpb_wrapper_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_img_with_animation_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 25px;
    margin-left: 6px;
    line-height: 0;
    color: #fff;
}

.sub_01_inner_grid {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_01_hover_wrap_inner {
    transition: -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1), -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    color: #fff;

}

.sub_01_img_with_animation_img {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    box-shadow: none;
    max-width: 100%;
    height: auto;
    position: relative;
    box-sizing: border-box;

}

.sub_01_nectar_split_heading_grid {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 24px;
    box-sizing: border-box;
}

.sub_01_nectar_heading_h1 {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 0;
    color: #fff;
    font-family: "RobotoCondensedSemiBold";
    letter-spacing: -1px;
    font-size: 82px;
    line-height: 0;
    font-weight: 700;

    @media (max-width: 800px) {
        font-size: 54px;
    }
}

.sub_01_span_inner {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); 
    opacity: 1;
    position: relative;
    display: inline-block;
    line-height: 1.2;
    vertical-align: bottom;
    box-sizing: border-box;
}

.sub_01_span_outter {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1.2;
    vertical-align: bottom;
    position: relative;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
}

.sub_01_nectar_3d_flip_button_grid {
    margin: 0;
    padding: 0;
    border: 0;

    margin-right: 45px;
    /* width: 150.539px;
    height: 54px; */
    visibility: visible;

    position: relative;
    margin-bottom: 0;

    display: inline-block;

    font-weight: 700;
    font-size: 12px;
    line-height: 20px;

    font-family: 'Roboto';

    box-sizing: border-box;

    @media (max-width: 800px) {
        margin-bottom: 20px;
    }

}

.sub_01_wpb_text_column {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 1.5em;

    padding-top: 20px!important;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding-top: 0px!important;
    }

}

.sub_01_wpb_wrapper_text_column {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
}

.sub_01_wpb_wrapper_text_column p {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;

    padding-bottom: 1.5em;
    color: #fff;
}

.sub_01_wpb_wrapper_text_column span {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
    color: #fff;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.1px;
}

.sub_01_wpb_raw_code_grid {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
}

.sub_01_btn_live_div {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
    color: #fff;
    margin-bottom: 24px;
}

.sub_01_btn_live_div button {
    /* border-radius: 5px!important; */
}

.sub_01_download_btn {
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FF5532;
    color: #fff;
    font-size: 20px;
    border-radius: 25px;
    padding: 0 33px;
    font-weight: bold;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    margin: 0;
    text-decoration: none;
    border-radius: 4px;

    box-shadow: none;
    box-sizing: border-box;

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1), -webkit-transform .45s cubic-bezier(0.25,1,0.33,1);

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), transform .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1);

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), transform .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1), -webkit-transform .45s cubic-bezier(0.25,1,0.33,1);
}


.sub_01_download_btn:hover {
    background-color: #ff2e03;
}
.sub_01_cubeshape {
	text-align: center;
	margin: 0 auto;
}

.sub_01_cubeshape h1 {
	margin: 0;
    padding: 0;
    font-size: 16px;
}


.sub_01_cubeshape h1 a {
	
}


.sub_01_cubeshape span {
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.sub_01_cube {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; /* Animate the transform properties */

    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; 
}

.sub_01_first_cube {
    background-color: transparent;
    border: 4px solid white;
    -webkit-transform: translateZ(30px);
    transform: translateZ(30px);
}

.sub_01_second_cube {
    background-color: white;
    color: black;
    border: 4px solid white;
    margin-top: -2px;
    -webkit-transform: rotateX(-90deg) translateZ(-30px);
    transform: rotateX(-90deg) translateZ(-30px); 

    @media (max-width: 800px) {
        display: none;
    }
}

.sub_01_first_cube span {
    cursor: pointer;
    color: white;
    padding: 14px;
    box-sizing: border-box;
}

.sub_01_second_cube span {
    cursor: pointer;
    color: #725b05;
    padding: 16px;
    box-sizing: border-box;
}

.sub_01_cube:hover {
    @media (min-width: 800px) {
        -webkit-transform: rotateX(89deg);
        transform: rotateX(89deg); 
    }
    
}
.sub_02_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
}

.sub_02_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    margin-top: 100px;
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px;
    }

} 

.sub_02_row_col_wrap_12_col {
    margin: 0;
    padding: 0;
    border: 0;

    margin-left: -20px;
    margin-right: -20px;

    display: flex;    
    flex-wrap: wrap;
    float: none;

    margin-bottom: 0!important;
    z-index: 10;
    width: auto;

    position: relative;
    box-sizing: border-box;
}

.sub_02_vc_col_sm_12 {
    padding: 0;
    border: 0;

    margin-top: 50px;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    background-position: center;

    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;

}

.sub_02_vc_col_sm_12 h2 {
    margin: 0;
    padding: 0;
    border: 0;

    font-family: 'RobotoBold';
    letter-spacing: -1px;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 7px;

    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 35px;
        line-height: 42px;
    }
}

.sub_02_wpb_row_vc_row_fluid {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    padding-bottom: calc(100vw * 0.07);
    margin-bottom: 0;
    box-sizing: border-box;
    background-size: cover;
    width: 100%;
    position: relative;

    @media (max-width: 800px) {
        padding: 0px
    }
}


.sub_02_vc_column_inner {
    margin: 0;
    padding: 0;
    border: 0;

    padding: calc(1245px * 0.01);
    position: relative;

    box-sizing: border-box;

    text-align: center;

}

.sub_02_wpb_wrapper_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_row_grid {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 40px;
    position: relative;
    box-sizing: border-box;
}

.sub_02_row_col_wrap_12_inner_grid {
    margin: 0;
    padding: 0;
    border: 0;

    margin-left: -20px;
    margin-right: -20px;

    margin-bottom: 0!important;

    display: flex;
    flex-wrap: wrap;
    float: none;

    z-index: 10;

    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_vc_col_sm_12_grid {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-transform: none;
            transform: none;
    opacity: 1;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    transition: opacity .6s cubic-bezier(.15,.84,.35,1.25), background-color .35s cubic-bezier(.65,0,.35,1);
    position: relative;
    box-shadow: none;
    background-position: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_nectar_icon_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_nectar_icon_grid {
    margin: 0;
    border: 2px solid rgba(0, 0, 0, 0.065);

    border-color: #ff5433;
    border-width: 2px;
    padding: 25px;
    line-height: 0;
    text-align: center;
    border-radius: 150px;
    position: relative;
    transition: background-color .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1);
    box-sizing: border-box;

}

.sub_02_im_icon_wrap_grid {
    margin: 0;
    padding: 0;
    border: 0;

    height: 60px;
    width: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_title_row_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 40px;
}

.sub_02_vc_col_sm_12_title_grid {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-transform: translateY(0px) translateZ(0px);
            transform: translateY(0px) translateZ(0px);
    opacity: 1;
    margin-left: 0;
    float: none;
    margin-right: 0;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    box-shadow: none;
    background-position: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;

}

.sub_02_wpb_text_column_grid {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 315px;
    display: inline-block;
    margin-bottom: 0;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_column_grid h4 {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Roboto';
    font-weight: 500;
    margin-bottom: 8px;
    color: #000000;
    letter-spacing: 0;
    font-size: 18px;
    line-height: 32px;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_row_text_grid {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 0;
    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_col_sm_12_inner_grid {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-transform: none;
            transform: none;
    opacity: 1;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    box-shadow: none;
    background-position: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_col_grid {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 315px;
    display: inline-block;
    margin-bottom: 0;
    margin-top: -20px !important;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_col_grid p {
    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 1.5em;
    box-sizing: border-box;
    text-align: center;
}

.sub_02_wpb_text_col_grid p span {
    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 1.5em;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_03_container_wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);

    margin: 0;
    border: 0;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    width: 100%;
}

.sub_03_container {
    margin: 0 auto;
    border: 0;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    width: 100%;

    max-width: 1550px;
    padding: 0 90px;

    @media (max-width: 800px) {
        padding: 0px
    }
}

.sub_03_row_bg_wrap {
    margin: 0;
    padding: 0;
    border: 0;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;
}

.sub_03_inner_wrapper {
    margin: 0;
    padding: 0;
    border: 0;

    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    box-sizing: border-box;

}

.sub_03_row_bg_viewport {
    margin: 0;
    padding: 0;
    border: 0;

    background-image: url(/static/media/headless_cms_scaled_02.7c14fdac.jpg);
    background-position: left top;
    background-repeat: no-repeat;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;

    box-sizing: border-box;
}

.sub_03_bg_overlay {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #000000;
    opacity: 0.3;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    z-index: 3;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    box-sizing: border-box;

}

.sub_03_row_col_12_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0!important;
    color: #fff;
    float: none;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
    width: auto;
    position: relative;
    box-sizing: border-box;
}

.sub_03_vc_col_sm_12 {
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;    
    box-shadow: none;
    background-position: center;
    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;
    color: #fff;
}

.sub_03_wpb_text_top {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;
    color: #fff;
}

.sub_03_wpb_text_top h2 {
    margin: 0;
    padding: 0;
    border: 0;
    color: #fff;
    font-family: 'RobotoCondensedRegular';
    letter-spacing: -1px;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;

    @media (max-width: 800px) {
        font-size: 35px;
        line-height: 42px;
    }
}

.sub_03_wpb_text_top p {

    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 1.5em;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.1px;
}

.sub_03_nectar_fancy_ul {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 24px;
    box-sizing: border-box;

}

.sub_03_nectar_fancy_ul ul {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 30px;
    box-sizing: border-box;

}

.sub_03_nectar_fancy_ul ul li {
    margin: 0;
    padding: 0;
    border: 0;

    font-weight: 400;
    list-style: none;
    position: relative;
    padding-left: 38px;

}

.sub_03_nectar_fancy_ul ul li i {
    margin: 0;
    padding: 0;
    border: 0;

    line-height: 32px;
    font-size: 16px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    color: #ff5433;
    font-style: normal;
    background-color: transparent;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    background-image: none;
    background-position: 0% 0%;
    background-repeat: repeat;
    margin-top: 0;

}

.sub_03_nectar_fancy_ul ul li span {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 400;
    box-sizing: border-box;
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_01_wpb_raw_code_grid {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
}

.sub_01_btn_live_div {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
    color: #fff;
    margin-bottom: 24px;
}

.sub_01_download_btn {
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FF5532;
    color: #fff;
    font-size: 20px;
    border-radius: 25px;
    padding: 0 33px;
    font-weight: bold;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    margin: 0;
    text-decoration: none;
    border-radius: 4px;

    box-shadow: none;
    box-sizing: border-box;

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1), -webkit-transform .45s cubic-bezier(0.25,1,0.33,1);

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), transform .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1);

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), transform .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1), -webkit-transform .45s cubic-bezier(0.25,1,0.33,1);
}


.sub_01_download_btn:hover {
    background-color: #ff2e03;
}
.sub_04_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
}

.sub_04_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    margin-top: 100px;
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px
    }

} 

.sub_04_row_col_wrap_12_col {
    margin: 0;
    padding: 0;
    border: 0;

    margin-left: -20px;
    margin-right: -20px;

    display: flex;    
    flex-wrap: wrap;
    float: none;

    margin-bottom: 0!important;
    z-index: 10;
    width: auto;

    position: relative;
    box-sizing: border-box;
}

.sub_04_vc_col_sm_12 {
    padding: 0;
    border: 0;

    margin-top: 50px;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    background-position: center;

    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;

}

.sub_04_vc_col_sm_12 h2 {
    margin: 0;
    padding: 0;
    border: 0;

    font-family: 'RobotoCondensedSemiBold';
    letter-spacing: -1px;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 7px;

    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 35px;
        line-height: 42px;
    }
}

.sub_04_wpb_row_vc_row_fluid {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    /* padding-bottom: calc(100vw * 0.07); */
    padding-bottom: 40px;
    margin-bottom: 0;
    box-sizing: border-box;
    background-size: cover;
    width: 100%;
    position: relative;
    @media (max-width: 800px) {
        padding: 0;
    }
}


.sub_04_vc_column_inner {
    margin: 0;
    padding: 0;
    border: 0;

    padding: calc(1245px * 0.01);
    position: relative;

    box-sizing: border-box;

    text-align: center;

}

.sub_04_wpb_wrapper_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    text-align: center;
}

.sub_04_nectar_star_rating {
    margin: 0;
    padding: 0;
    border: 0;

    font-size: 20px;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
}

.sub_04_star_rating_icons {
    margin: 0;
    padding: 0;
    border: 0;

    font-size: inherit;
    line-height: 1.3;
    color: #ff5433;

    box-sizing: border-box;

}

.sub_04_rate {
    float: left;
    /* height: 46px;
    padding: 0 10px; */
}
.sub_04_rate > label {
    float: left;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 20px;
    color: #ccc;
    margin-right: 8px;
}

.sub_04_rate > label:before {
    content: '★ ';
    color: #ff5433;
    font-size:25px;
}

.sub_04_img_with_animation_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 0;
    margin-bottom: 0;
    box-sizing: border-box;
}

.sub_04_inner_rating {
    margin: 0;
    padding: 0;
    border: 0;
    height: 100%;
    box-sizing: border-box;
}

.sub_04_hover_wrap_rating {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    overflow: visible;
    opacity: 1;
    margin-bottom: 15px;
    position: relative;
   
}

.sub_04_hover_wrap_inner_rating {
    transition: -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1), -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.sub_04_hover_wrap_inner_rating img {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    box-shadow: none;
    max-width: 100%;
    height: auto;
    position: relative;
}

.sub_04_wpb_text_column_rating {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-bottom: 0;
}

.sub_04_wpb_text_column_rating p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: left;
}

.sub_04_wpb_text_column_rating p span {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-bottom: 0;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}
.sub_06_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
}

.sub_06_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    /* margin-top: 100px; */
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0;
    }


} 

.sub_06_wpb_row_vc_fluid {
    margin: 0;
    padding: 0;
    border: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 200px;
    /* margin-top: 200px; */
    position: relative;
    box-sizing: border-box;
}

.sub_06_vc_column_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
}

.sub_06_inner {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_06_hover_wrap_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    transition: -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1), -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
}

.sub_06_hover_wrap_inner img {
    margin: 0;
    padding: 0;
    border: 0;

    box-shadow: none;
    
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    max-width: 100%;
    height: auto;
    position: relative;
}

.sub_06_data_tf_live {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-bottom: 24px;

}

.sub_06_content_form_div {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-inline: 80px;
    text-align: start;

    @media (max-width: 800px) {
        padding-inline: 40px;
    }

}

.sub_06_root_sc_sc {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    width: 100%;
    max-width: 720px;
    margin: 0px auto;
    padding-inline: 0px;
}

.sub_06_question_heading {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-top: 32px;

    position: relative;
    -webkit-font-smoothing: antialiased;
    display: flex;
    overflow-wrap: break-word;

    text-align: start;

    @media (max-width: 800px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

}

.sub_06_question_heading span {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin: 0px;
    max-width: 100%;
    width: inherit;
    font-family: 'Roboto';
    font-size: 24px;
    line-height: 32px;
    color: rgb(0, 0, 0);
    text-align: start;

    @media (max-width: 800px) {
        font-size: 20px;
        line-height: 28px;
    }
}

.sub_06_spacewrapper {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    -webkit-margin-before: 8px;

            margin-block-start: 8px;
    text-align: start;
}





.rvp_pl_5_mr_1 {
    /* padding-left: 1.25rem; */
    box-sizing: border-box;
    /* width: 49%; */
    margin-bottom: 48px;

    @media (max-width: 800px) {
        margin-bottom: 25px;
    }
}

.rvp_field_wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding-right: 0;

    }
}

.rvp_flex_col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_col_label_div {
    display: flex;
    width: 100%;
    font-weight: 500;
    margin-bottom: 9px;
    color: #2b62bb;

    font-size: 16px;
    font-family: 'Roboto';
    line-height: 24px;

    @media (max-width: 800px) {
        font-size: 14px;
        line-height: 22px;
    }
}

.rvp_input_field_div {
    position: relative;
    width: 100%;
    margin-bottom: 9px;
}

.rvp_input_field_text {
    border-width: 1px;
    border-color: rgb(179 199 231);
    background-color: white;
    color: #0d4cb2;
    font-size: 24px;
    line-height: 24px;
    min-height: 2.375rem;
    touch-action: manipulation;
    width: 100%;
    box-sizing: border-box;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;

    @media (max-width: 800px) {
        font-size: 20px;
        line-height: 20px;
    }
}

.rvp_input_field_text:focus-visible {
    border-width: 2px;
    border-color: rgb(20, 106, 243);
    /* background: rgb(255, 255, 255); */
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    outline: none;
}

::-webkit-input-placeholder {
    color: #b4c7e7;
    opacity: 1; /* Firefox */
  }

:-ms-input-placeholder {
    color: #b4c7e7;
    opacity: 1; /* Firefox */
  }

::placeholder {
    color: #b4c7e7;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12-18 */
    color: #b4c7e7;
  }

  .sub_06_submit_btn_div {
    position: relative;
  }

  .sub_06_btn_container {
    margin-top: 16px;
  }

  .sub_06_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 auto;

  }

  .sub_06_btn_wrapper_div {
    display: flex;
    flex-flow: wrap;
    position: relative;
    align-items: center;
    -webkit-margin-after: 0px;
            margin-block-end: 0px;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 800px) {
        width: 100%;
    }
  }

  .sub_06_btn_div {
    position: relative;
    font-family: inherit;
    line-height: normal;
    line-height: initial;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    outline: none;
    border: 1px solid transparent;
    margin: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
    /* padding-block: 6px; */
    padding-inline: 14px;
    min-height: 40px;
    background-color: rgb(255 85 50);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    width: 100%;
    text-transform: none;
    display: flex;
  }
  

  .sub_06_btn_div:hover {
    background-color: rgb(245, 120, 94);
  }

  .sub_06_btn_text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;

    @media (max-width: 800px) {
        font-size: 16px;
        line-height: 16px;
    }
  }




.sub_07_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);

    background-color: #f7f7f7;
}

.sub_07_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0;

    }

} 

.sub_07_wpb_row_vc_fluid {
    margin: 0;
    padding: 0;
    border: 0;
   
    position: relative;
    box-sizing: border-box;
}

.sub_07_row_col_wrap_12 {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-wrap: wrap;
    float: none;
    margin-bottom: 0!important;
    z-index: 10;
    width: auto;
    position: relative;
}

.sub_07_vc_col_sm_12 {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;

    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    background-position: center;
    width: 100%;

}

.sub_07_vc_row_fluid_ft {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0;
    position: relative;
    background-size: cover;
    width: 100%;
}

.sub_07_wpb_wrapper {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_07_img_with_animation {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    line-height: 0;
    margin-bottom: 0;
}

.sub_07_inner_img_div {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    height: 100%;
}

.sub_07_hover_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    overflow: visible;
    opacity: 1;
    margin-bottom: 15px;
    position: relative;

    @media (max-width: 800px) {
        margin-bottom: 35px;
    }
}

.sub_07_hover_wrap_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    transition: -webkit-transform .65s cubic-bezier(.05,.2,.1,1);

    transition: transform .65s cubic-bezier(.05,.2,.1,1);

    transition: transform .65s cubic-bezier(.05,.2,.1,1), -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
}

.sub_07_hover_wrap_inner img {
    @media (max-width: 800px) {
        max-width: 100%;
        height: auto;

    }
}

.sub_07_nectar_split_heading {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-bottom: 24px;
}

.sub_07_nectar_split_heading p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    line-height: 32px;
}

.sub_07_nectar_split_heading p span {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    line-height: 1.2;
    vertical-align: bottom;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.sub_07_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    position: relative;
    display: inline-block;

    line-height: 1.2;

    vertical-align: bottom;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_07_wpb_text_column_ft {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_07_wpb_text_column_ft p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-bottom: 1.5em;
}

.sub_07_wpb_text_column_ft p a {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    color: #ff5433;
    text-decoration: none;
    transition: color .3s;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_07_professor_prebid_root {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-bottom: 24px;
}

.sub_07_wpb_wrapper_02 {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_07_wpb_wrapper_02 p {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-bottom: 1.5em;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_07_wpb_wrapper_02 p a {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    color: #ff5433;
    text-decoration: none;
    transition: color .3s;
}
.sub_08_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
    background-color: #fafafa;
}

.sub_08_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    /* margin-top: 100px; */
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0;
    }

} 

.sub_08_wpb_row_vc_fluid {
    margin: 0;
    padding: 0;
    border: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    /* margin-bottom: 150px; */
    /* margin-top: 150px; */
    background-size: cover;
    width: 100%;
    position: relative;
    box-sizing: border-box;

}

.sub_08_row_col_wrap_12 {
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0!important;
    float: none;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
    width: auto;
    position: relative;
    box-sizing: border-box;
}

.sub_08_vc_col_sm_12 {
    
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    box-shadow: none;
    background-position: center;
    position: relative;
    width: 100%;
    display: block;
}

.sub_08_wpb_text_column {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;

}

.sub_08_wpb_text_column p {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;

    font-family: 'RobotoCondensedRegular';
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -1px;
}

.sub_08_img_with_animation {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 0;
    margin-bottom: 0;
    box-sizing: border-box;

}

.sub_08_inner {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_08_inner img {
   

    @media (max-width: 800px) {
        
            height: 22px;
    }
}

.sub_08_hover_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 15px;
    opacity: 1;
    overflow: visible;
}

.sub_08_hover_wrap_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    transition: -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1), -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
}


.carousel-container {
    overflow: hidden;
    position: relative;
    height: 100px;
    border-color: #4b5563;
    width: 100%;
  }
  
  .carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* &:hover {
      transform: scale(0.9);
      box-shadow: 5px 5px 8px rgba(222, 222, 222, 0.362),
        10px 10px 8px rgba(152, 133, 133, 0.392),
        15px 15px 8px rgba(139, 154, 139, 0.303);
    } */
  }
  
  .carousel-card > img {
    height: 80%;
    border-radius: 10%;
  }
  
  .carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100px;
    grid-gap: 16px;
    gap: 16px;
    width: 200%;
  }

  .carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 80px;
    grid-gap: 16px;
    gap: 16px;
    width: 200%;
    height: 100%;
    -webkit-animation: slide 50s linear infinite;
            animation: slide 50s linear infinite;
    /* &:hover {
        animation-play-state: paused;
    } */
  }

  @-webkit-keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  @keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
.sub_09_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
    background-color: #fafafa;
}

.sub_09_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    /* margin-top: 100px; */
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0;
    }

} 

.sub_09_wpb_row_vc_fluid {
    margin: 0;
    padding: 0;
    border: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    /* margin-bottom: 150px; */
    /* margin-top: 150px; */
    background-size: cover;
    width: 100%;
    position: relative;
    box-sizing: border-box;

}

.sub_09_row_col_wrap_12 {
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0!important;
    float: none;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
    width: auto;
    position: relative;
    box-sizing: border-box;
}

.sub_09_vc_col_sm_12 {
    
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    box-shadow: none;
    background-position: center;
    position: relative;
    width: 100%;
    display: block;
}

.sub_09_wpb_text_column {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;

}

.sub_09_wpb_text_column p {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;

    font-family: 'RobotoCondensedRegular';
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -1px;
}

.sub_09_img_with_animation {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 0;
    margin-bottom: 0;
    box-sizing: border-box;

}

.sub_09_inner {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_09_inner img {
   

    @media (max-width: 800px) {
        
            height: 22px;
    }
}

.sub_09_hover_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 15px;
    opacity: 1;
    overflow: visible;
}

.sub_09_hover_wrap_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    transition: -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
    transition: transform .65s cubic-bezier(.05,.2,.1,1), -webkit-transform .65s cubic-bezier(.05,.2,.1,1);
}


.carousel-container {
    overflow: hidden;
    position: relative;
    height: 100px;
    border-color: #4b5563;
    width: 100%;
  }
  
  .carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* &:hover {
      transform: scale(0.9);
      box-shadow: 5px 5px 8px rgba(222, 222, 222, 0.362),
        10px 10px 8px rgba(152, 133, 133, 0.392),
        15px 15px 8px rgba(139, 154, 139, 0.303);
    } */
  }
  
  .carousel-card > img {
    height: 80%;
    border-radius: 10%;
  }
  
  .carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100px;
    grid-gap: 16px;
    gap: 16px;
    width: 200%;
  }

  .carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 80px;
    grid-gap: 16px;
    gap: 16px;
    width: 200%;
    height: 100%;
    -webkit-animation: slide 50s linear infinite;
            animation: slide 50s linear infinite;
    /* &:hover {
        animation-play-state: paused;
    } */
  }

  @-webkit-keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  @keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
.pfs_main_container {
    padding-bottom: .75rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-top: .75rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.pfs_main_wrapper {
    flex-grow: 1;
    max-width: 1150px;

    @media (max-width: 800px) {
        width: 100%;
    }
}

.pfs_rounded {
    box-shadow:  0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a,0 1px 2px -1px #0000001a;
    background-color: #ffffff;
    border-radius: .25rem;
    color: #131310;
}

.pfs_flex_py_3 {
    border-color: #efeeea;
    border-top-style: solid;
    border-bottom-width: 0;
    border-top-width: 1px;
    padding-bottom: .75rem;
    padding-top: .75rem;
    display: flex;

    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.pfs_p_3_w_2_3 {
    padding: .75rem;
    grid-gap: .75rem;
    gap: .75rem;
    align-items: flex-start;
    width: 66.6666667%;
    display: flex;

    @media (max-width: 800px) {
        width: 93%;
        flex-direction: column;
    }
}

.pfs_p_3_w_1_3 {
    padding: .75rem;
    border-color: #efeeea;
    border-left-width: 1px;
    border-left-style: solid;
    width: 33.3333333%;

    @media (max-width: 800px) {
        width: 93%;
        flex-direction: column;
    }
}

.pfs_w_auto {
    width: auto;
}

.pfs_w_52px {
    background-size: cover;
    border-radius: 9999px;
    overflow: hidden;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    width: 52px;
    height: 52px;
    display: flex;
}

.pfs_w_52px > img {
    height: auto;
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

.pfs_grow_w_676 {
    flex-grow: 1;
    width: 676.66px;

    @media (max-width: 800px) {
        width: 95%;
    }
}

.pfs_w_full_flex {
    grid-gap: .75rem;
    gap: .75rem;
    align-items: center;
    width: 100%;
    display: flex;
    margin-bottom: .75rem;

}

.pfs_font_semibold {
    font-weight: 600;
}

.pfs_cursor_pointer {
    cursor: pointer;
}

.pfs_border_1 {
    padding-top: 1rem;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    border-left-width: 1px;
}

.pfs_text_xs {
    font-size: .75rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
}

.pfs_text_icon {
    font-weight: 300;
    font-size: .875em;
    line-height: .07143em;
    vertical-align: .05357em;
    font-family: "Font Awesome 6 Sharp";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    color: #acaca9;
}

.pfs_text_8B8B88 {
    color: #8b8b88;
    word-break: break-word;
}

.pfs_flex_tems_start {
    font-size: .875rem;
    line-height: 1.25rem;
    grid-gap: .5rem;
    gap: .5rem;
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem;
}

.pfs_flex_justify_center_item {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a,0 1px 2px -1px #0000001a;
    background-color: #f7f6f4;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    display: flex;
}



.pfs_brief_icon {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_font_medium_des {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    max-width: 95%;
    word-break: break-word;

    @media (max-width: 800px) {
        flex-direction: column!important;
    }
}

.pfs_font_medium_activity {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    max-width: 95%;
    word-break: break-word;
}

.pfs_mobile_none {
    @media (max-width: 800px) {
        display: none;
    }
}

.pfs_desktop_none {
    @media (min-width: 800px) {
        display: none;
    }
}

.pfs_flex_items_center_gap_2 {
    font-size: .875rem;
    line-height: 1.25rem;
    grid-gap: .5rem;
    gap: .5rem;
    /* align-items: center; */
    display: flex;
    margin: 5px 0;
    
}

.pfs_buildings_icon {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_flex_items_start_gap {
    font-size: .875rem;
    line-height: 1.25rem;
    grid-gap: .5rem;
    gap: .5rem;
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem;
}

.pfs_envelope {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_font_medium_text_xs {
    font-weight: 500;
    font-size: .75rem;
    line-height: 1.25rem;
}

.pfs_binoculars {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.pfs_word_break {
    word-break: break-word;
}

.pfs_word_break_normal {
    word-break: normal;
}
.admin_pfs_main_container {
    padding-bottom: .75rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-top: .75rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.admin_pfs_main_wrapper {
    flex-grow: 1;
    max-width: 1150px;

    @media (max-width: 800px) {
        width: 100%;
    }
}

.admin_pfs_rounded {
    box-shadow:  0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a,0 1px 2px -1px #0000001a;
    background-color: #ffffff;
    border-radius: .25rem;
    color: #131310;
}

.admin_pfs_flex_py_3 {
    border-color: #efeeea;
    border-top-style: solid;
    border-bottom-width: 0;
    border-top-width: 1px;
    padding-bottom: .75rem;
    padding-top: .75rem;
    display: flex;

    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.admin_pfs_p_3_w_2_3 {
    padding: .75rem;
    grid-gap: .75rem;
    gap: .75rem;
    align-items: flex-start;
    width: 66.6666667%;
    display: flex;

    @media (max-width: 800px) {
        width: 93%;
        flex-direction: column;
    }

}

.admin_pfs_p_3_w_1_3 {
    padding: .75rem;
    border-color: #efeeea;
    border-left-width: 1px;
    border-left-style: solid;
    width: 33.3333333%;

    @media (max-width: 800px) {
        width: 93%;
        flex-direction: column;
    }
}

.admin_pfs_w_auto {
    width: auto;
}

.admin_pfs_w_52px {
    background-size: cover;
    border-radius: 9999px;
    overflow: hidden;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    width: 52px;
    height: 52px;
    display: flex;
}

.admin_pfs_w_52px > img {
    height: auto;
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

.admin_pfs_grow_w_676 {
    flex-grow: 1;
    width: 676.66px;

    @media (max-width: 800px) {
        width: 95%;
    }
    
}

.admin_pfs_w_full_flex {
    grid-gap: .75rem;
    gap: .75rem;
    align-items: center;
    width: 100%;
    display: flex;
    margin-bottom: .75rem;

}

.admin_pfs_font_semibold {
    font-weight: 600;
}

.admin_pfs_cursor_pointer {
    cursor: pointer;
}

.admin_pfs_border_1 {
    padding-top: 1rem;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    border-left-width: 1px;
}

.admin_pfs_text_xs {
    font-size: .75rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
}

.admin_pfs_text_icon {
    font-weight: 300;
    font-size: .875em;
    line-height: .07143em;
    vertical-align: .05357em;
    font-family: "Font Awesome 6 Sharp";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    color: #acaca9;
}

.admin_pfs_text_8B8B88 {
    color: #8b8b88;
}

.admin_pfs_flex_tems_start {
    font-size: .875rem;
    line-height: 1.25rem;
    grid-gap: .5rem;
    gap: .5rem;
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem;
}

.admin_pfs_flex_justify_center_item {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a,0 1px 2px -1px #0000001a;
    background-color: #f7f6f4;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    display: flex;
}



.admin_pfs_brief_icon {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.admin_pfs_font_medium {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    max-width: 95%;
}

.admin_pfs_font_medium_des {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    max-width: 95%;

    @media (max-width: 800px) {
        flex-direction: column;
        word-break: break-word;
    }
}

.admin_pfs_word_break_normal {
    @media (max-width: 800px) {
        word-break: normal;
    }
}
.admin_pfs_mobile_none {
    @media (max-width: 800px) {
        display: none;
    }
}
.admin_pfs_word_break {
    @media (max-width: 800px) {
        word-break: break-word;
    }
}

.admin_pfs_flex_items_center_gap_2 {
    font-size: .875rem;
    line-height: 1.25rem;
    grid-gap: .5rem;
    gap: .5rem;
    /* align-items: center; */
    display: flex;
    margin: 5px 0;
    
}

.admin_pfs_buildings_icon {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.admin_pfs_flex_items_start_gap {
    font-size: .875rem;
    line-height: 1.25rem;
    grid-gap: .5rem;
    gap: .5rem;
    align-items: center;
    display: flex;
    margin-bottom: .5rem;
}

.admin_pfs_flex_items_start_gap_linkedin {
    font-size: .875rem;
    line-height: 1.25rem;
    grid-gap: .5rem;
    gap: .5rem;
    align-items: center;
    display: flex;
    margin-bottom: 0;
}

.admin_linkedin_a {
    display: flex;
    text-decoration: none;
    color: #131310;
}

.admin_linkedin_a:hover {
    color: #10c9f5;
}

.admin_pfs_envelope {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}

.admin_pfs_font_medium_text_xs {
    font-weight: 500;
    font-size: .75rem;
    line-height: 1.25rem;
    display: flex;
    align-items: flex-start;
}

.admin_pfs_binoculars {
    font-weight: 400;
    font-size: .75em;
    line-height: .08333em;
    vertical-align: .125em;
    font-family: "Font Awesome 6 Pro";
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
}
.sameline_title_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quiz_content_item_div {
    border: 1px solid #c0c2c3a3;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
    transition: all 150ms ease-in-out;
    margin-bottom: 5px!important;
}

.quiz_content_item_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
}

.quiz_content_item_title {
    /* font-size: 16px; */
    font-weight: 600;
}

.quiz_content_item_text {
    overflow-wrap: anywhere;
}

.quiz_child_div {
    padding: 10px 15px!important;
    border: 1px #ebe7e7 solid!important;
    border-radius: 5px!important;
    margin-bottom: 10px;
    transition: all 150ms ease-in-out;
}

.quiz_child_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
 }
body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root1 {
    background-color: #fff;
    @media (max-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    
}
.total_container {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div {
    flex: 1 0 auto;
}
.foot_img_div {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img {
    height: 149px;
    width: 100%;
    /* background-image: url("assets/houses2.webp"); */
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div {
  padding: 25px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result {
  padding: 15px 0px;
  /* border-bottom: 1px solid rgb(230,230,230); */
}
.content_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div {
    width: 115px;
}
.content_quiz_div {
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.result_div_font {
    font-family: "Roboto";
}
.quiz_div_fadeOut {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn {
  opacity: 1;
  transition: opacity 1.5s;
  
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
.content_quiz_container {
    margin-top: 60px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 15px;
}
.quiz_question_div {
    font-size: 40px!important;
    line-height: 44px!important;
    margin-bottom: 50px;
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container {
    display: flex;
    justify-content: center;
}
.quiz_answers_button_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    min-height: 190px;
    transition: -webkit-transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s, -webkit-transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
}
.quiz_answers_button_div:hover {
    -webkit-transform: translate(0px, -5%);
            transform: translate(0px, -5%);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span {
    background-image: url(/static/media/yes.50e2ec53.svg);
    background-size: 70%;
    margin: 20px auto 0px;
    display: block;
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span {
    /* height: 55px; */
    margin: 5px;
    line-height: 22px;
    /* font-size: 17px; */
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    text-align: center;
    display: block;
}

.quiz-container1 {
    @media (min-width: 800px) {
      width: 800px;
      min-width: 250px;
      background: #ffffff;
      border-radius: 4px;
      margin: 50px auto;
      padding: 100px 60px;
      display: flex;
      flex-direction: column;
      background: transparent;
    }
  
    background: #ffffff;
    border-radius: 4px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  .quiz-container1 h2 {
    /* text-align: center; */
    @media (min-width: 800px) {
      font-size: 1.8rem;
      color: #161a1f;
      line-height: 1.4;
      font-weight: 500;
      margin: 0 0 0 0;
    }
  
    font-size: 1.5rem;
    color: #161a1f;
    line-height: 1.3;
    font-weight: 500;
    margin: 0 0 0 0;
  }
  
  .quiz-container1 h4 {
    @media (min-width: 800px) {
      font-size: 1.6rem;
      color: #161a1f9c;
      line-height: 1.4;
      font-weight: 500;
      margin: 10px 0 0 0;
    }
    font-size: 1.3rem;
    color: #161a1f9c;
    line-height: 1.2;
    font-weight: 500;
    margin: 10px 0 0 0;
  }

  .quiz-container1 ul {
    margin-top: 20px;
    margin-left: -40px;
  }

  
.quiz-container1 ul li {
    @media (min-width: 800px) {
      font-size: 1.3rem;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    /* font-size: 16px; */
    /* font-size: 1.3rem; */
    /* background: #ffffff; */
    background: #8ebaf133;
    /* border: 1px solid #eaeaea; */
    border-radius: 5px;
    padding: 25px 25px;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 350ms ease-in-out;
  }
  
  .quiz-container1 ul li .txt_index {
    border: 2px solid #0571f782;
    padding: 8px 12px;
    line-height: 1;
    text-align: center;
    border-radius: 5px;
    background: white;
  }

  .quiz-container1 ul li:hover {
    border: 1px solid #0089ff;
  }

  .quiz-container1 ul .selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
  }

  .quiz-container1 button {
    background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }

  .quiz-container1 button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
  }

  .quiz-container1 .choice_div {
    @media (min-width: 800px) {
      width: 500px;
      /* width: 100%; */
      max-width: 500px;
      margin: 45px 0 0 0;
    }
    /* width: 500px; */
    /* width: 100%; */
    max-width: 500px;
    margin: 0 0 0 0;
  }
  
body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div_m a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root2 {
    @media (min-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    background-color: #fff;
}
.total_container_m {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div_m {
    flex: 1 0 auto;
}
.foot_img_div_m {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img_m {
    height: 149px;
    width: 100%;
    background-image: url(/static/media/houses2.53bdc365.webp);
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links_m {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container_m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container_m a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div_m {
  padding: 15px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result_m {
    padding: 15px 0px;
}
.content_head_m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result_m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div_m {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div_m:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div_m {
    width: 115px;
}
.content_quiz_div_m {
    /* max-width: 1200px; */
    padding: 0 5px;
    margin: 0 auto;
}
.result_div_font_m {
    font-family: "Roboto";
}
.quiz_div_fadeOut_m {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn_m {
  opacity: 1;
  transition: opacity 1.5s;
}
.content_quiz_container_m {
    margin-top: 30px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 5px;
}
.quiz_question_div_m {
    font-size: 25px!important;
    line-height: 25px!important;
    /* margin-bottom: 50px; */
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container_m {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.quiz_answers_button_div_m {
        display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* border-radius: 10px; */
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    /* min-height: 190px; */
    transition: -webkit-transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s, -webkit-transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
    width: 85%;
    padding: 0 0 0 15px;
    margin-bottom: 15px;
}
.quiz_answers_button_div_m:hover {
    /* transform: translate(0px, -5%); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span_m {
    background-image: url(/static/media/yes.50e2ec53.svg);
    background-size: 70%;
    /* margin: 20px auto 0px; */
    display: block;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span_m {
    min-height: 45px;
    margin: 5px;
    line-height: 22px;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    /* text-align: center; */
    text-align: left;
    display: flex;
    align-items: center;
}
.rvp_talk_advisor_container {
    width: 100%;
    max-width: 760px;
    line-height: 1.4;
    color: #000;
}

.rvp_talk_advisor_flex {
    grid-column-gap: 1.5rem;
    grid-row-gap: 0rem;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, .3fr) minmax(0, 1.55fr);
    grid-auto-columns: 1fr;
    align-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: .75rem;
    display: grid;

    @media screen and (max-width: 800px) {
        grid-column-gap: 10px;
        padding: 0 20px;
    }
}

.rvp_advisor_photo {
    grid-area: span 2 / span 1 / span 2 / span 1;
    width: 100%;
    position: relative;
}

.rvp_ssr_talk_advisor_photo {
    z-index: 5;
    border: 2px solid #fff;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
}

.rvp_ssr_image_cover_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;

    background-color: transparent;
    max-width: 100%;
    display: inline-block;

    vertical-align: middle;
    background: #9ba0a8;

    display: flex;
    justify-content: center;
    align-items: center;
}

.rvp_ssr_talk_advisor_photo_shadow {
    background-color: #0266fd;
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
    -webkit-transform: translate(-7px, -7px);
            transform: translate(-7px, -7px);
}

.rvp_advisor_title {
    align-self: end;
    box-sizing: border-box;
    text-align: start;

    @media screen and (max-width: 800px) {
        order: -9999;
        grid-area: span 1 / span 2 / span 1 / span 2;
    }
}

.rvp_ssr_h1 {
    margin-bottom: 8px;
    font-size: 26px;
    font-weight: 700;
    margin-top: 0;
    line-height: 1.3;
    color: #00162a;
}

.rvp_advisor_info {
    align-self: start;
}

.rvp_ssr_talk_advisor_checklist {
    grid-column-gap: .25rem;
    grid-row-gap: .25rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-left: 10px;
    display: grid;

    box-sizing: border-box;
}

.rvp_ssr_talk_advisor_checkitem {
    background-image: url(https://cdn.prod.website-files.com/5dbc34a79684004f6e7a6c30/6279e57ed5cd4f5cf6049f14_green_check_checklist_mark_icon.svg);
    background-position: 0 4px;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 22px;
    font-size: 16px;
    font-weight: 700;
    text-align: start;

    box-sizing: border-box;
}

.rvp_ssr_talk_advisor_p {
    margin-bottom: 12px;
    line-height: 1.4;
    color: #3d475c;
    font-size: 18px;
    margin-top: 0;
}

.rvp_content_container {
    width: 100%;
    max-width: 760px;
    margin-top: 40px;
    line-height: 1.4;
    color: #000;
}

.rvp_question_container {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
}

.rvp_question_col {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.rvp_col_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.rvp_col_ques_title {
    font-size: 1.5rem;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
        text-align: left;
    }
}

.rvp_col_sub_str {
    font-size: 0.8125rem;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
        text-align: left;
    }
}

.rvp_pl_5_mr_1 {
    /* padding-left: 1.25rem; */
    box-sizing: border-box;
    width: 49%;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.rvp_field_wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;
}

.rvp_flex_col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_col_label_div {
    display: flex;
    width: 100%;
    font-weight: 500;
    margin-bottom: 9px;
}

.rvp_input_field_div {
    position: relative;
    width: 100%;
    margin-bottom: 9px;
}

.rvp_input_field_text {
    border-width: 1px;
    border-color: rgba(75, 85, 99, 0.2);
    /* background: rgb(255, 255, 255); */
    background-color: transparent;
    color: rgb(75, 85, 99);
    font-size: 0.9375rem;
    line-height: 1.2em;
    min-height: 2.375rem;
    touch-action: manipulation;    
    border-radius: .25rem;
    width: 100%;
    padding: .5rem .75rem;
    margin: 0;
    box-sizing: border-box;
}

.rvp_input_field_text_email {
    border-width: 1px;
    border-color: rgba(75, 85, 99, 0.2);
    /* background: rgb(255, 255, 255); */
    background-color: transparent;
    color: rgb(75, 85, 99);
    font-size: 0.9375rem;
    line-height: 1.2em;
    min-height: 2.375rem;
    touch-action: manipulation;    
    border-radius: .25rem;
    width: 100%;
    padding: .5rem .75rem;
    padding-left: 2.5rem;
    margin: 0;
    box-sizing: border-box;
}

.rvp_input_field_text_email::-webkit-input-placeholder {
    color: #c1c4dc;
}

.rvp_input_field_text_email:-ms-input-placeholder {
    color: #c1c4dc;
}

.rvp_input_field_text_email::placeholder {
    color: #c1c4dc;
}

.rvp_absolute_mail_svg {
    position: absolute;
    padding-left: .75rem;
    align-items: center;
    display: flex;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    box-sizing: border-box;
}

.rvp_required_svg {
    width: 1rem;
    height: 1rem;
}

.rvp_btn_div {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;
}

.rvp_btn_flex_div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
}

.rvp_btn_btn {
    display: inline-flex;
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-color: transparent;
    border-width: 1px;
    border-radius: .375rem;
    justify-content: center;
    align-items: center;
    text-transform: none;
    overflow: visible;
    margin: 0;
    touch-action: manipulation;
    cursor: pointer;
    box-sizing: border-box;
}

.rvp_btn_btn:hover {
   -webkit-transform: none;
           transform: none;
   background: rgb(88, 149, 247) !important;;
}

.rvp_ant_space {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_ant_space_item {
    box-sizing: border-box;
}

.rvp_thanks_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}

.rvp_thanks_container {
    /* background: #fff; */
    background-color: transparent;
    max-width: 650px;
    border-radius: .5rem;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    position: relative;
    box-sizing: border-box;
}

.rvp_thanks_check_div_wrapper {
    margin-bottom: .75rem;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.rvp_thanks_check_container {
    width: 48px;
    height: 48px;
    background: #3b82f61a;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;
}

.rvp_thanks_str_div_wrapper {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
}

.rvp_thanks_str_title_div {
    color: #374151;
    box-sizing: border-box;
}

.rvp_thanks_str_title_wrapper {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: center;
}

.rvp_thanks_str_title_p {
    font-size: 1.875rem;
    line-height: 1.3em;
    font-weight: 700;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

}

.rvp_thanks_str_substr_div {
    margin-top: 3px;
    box-sizing: border-box;
    color: #9ba0a8;
    text-align: center;
}

.rvp_thanks_str_substr_p {
    font-size: 0.9375rem;
    line-height: 1.3em;
    font-weight: 400;
    margin-bottom: 0px;
    color: #9ba0a8;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rvp_circle_progress_div_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}


.card_div {
    padding: 15px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .05), 0 5px 15px 0 rgba(0, 0, 0, .08);
    border-radius: 16px;
    margin: 0;
    box-sizing: border-box;
}

.card_sub_div_1 {
    display: flex;
    align-items: center;
    margin: 0;
    box-sizing: border-box;
}

.rank_num {
    width: 16%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 40px;
    font-weight: 700;
    /* width: 33%; */
    flex-shrink: 0;
    line-height: 1;
    text-align: center;
    color: #003544;
    margin: 0;
    box-sizing: border-box;
}

.card_logo {
    padding: 0 20px;
    flex-grow: 1;
    font-size: 20px;
    font-weight: 600;
    /* font-size: 40px;
    font-weight: 700; */
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
    }
    
}

.imageuploadinner_card_logo {
    position: relative;

    @media (min-width: 800px) {
      width: 200px;
      height: 200px;
    }

    width: 150px;
    height: 150px;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        border-radius: 24px!important;
        object-fit: contain;
      }
}

.visit_btn {
    width: 33%;
    height: 49px;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 1rem 1.5rem;
    color: #fff;
    background-color: #e3800d;
    border: transparent;
    transition: background-color .5s cubic-bezier(.37,.01,0,.98);
    /* display: inline-flex; */
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    /* border-width: 1px; */
    /* border-style: solid; */
    border-radius: .5rem;
    margin: 0;
    box-sizing: border-box;
    cursor: pointer;

    @media (max-width: 800px) {
        display: none;
    }
}


.visit_btn:hover {
    background-color: #f18d17;
}












.card_sub_div_2 {
    padding: 16px;
    display: flex;
    overflow: hidden;
    background-color: #f5f6f8;
    border-radius: 8px;
    flex-direction: row-reverse;
    grid-gap: 0 20px;
    gap: 0 20px;
    display: flex;
    align-items: center;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        flex-direction: column;
        grid-row-gap: 16px;
        row-gap: 16px;
    }
}

.rating_div {
    width: 33%;
    padding: 8px;
    flex-direction: column;
    grid-gap: 4px 0;
    gap: 4px 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* column-gap: 16px; */
    line-height: 1;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    color: #003544;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 100%;
        flex-direction: row;
        grid-column-gap: 16px;
        -webkit-column-gap: 16px;
                column-gap: 16px;
    }
}

.rating_txt_01 {
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    margin: 0;
    box-sizing: border-box;
}

.rating_txt_02 {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin: 0;
    box-sizing: border-box;
}

.rating_txt_03 {
    font-family: var(--font-roboto-slab);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    box-sizing: border-box;
}

.p_description_div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 100%;
    }
}

.p_description_sub_div {
    display: flex;
    grid-column-gap: 11px;
    -webkit-column-gap: 11px;
            column-gap: 11px;
    margin: 0;
    box-sizing: border-box;
    align-items: center;
}

.p_description_text {
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    line-height: 16px;
    color: #003544;
    margin: 0;
    box-sizing: border-box;
}

.badge_div {
    margin: 6px 16px 0;
    display: flex;
    align-items: center;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    color: #fff; 
}

.badge_svg {
    width: 20px;
    height: 20px;
    stroke: currentcolor;
}

.badge_txt {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 700;
}
.card_div {
    padding: 15px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .05), 0 5px 15px 0 rgba(0, 0, 0, .08);
    border-radius: 16px;
    margin: 0;
    box-sizing: border-box;
}

.card_sub_div_1 {
    display: flex;
    align-items: center;
    margin: 0;
    box-sizing: border-box;
}

.rank_num {
    width: 16%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 40px;
    font-weight: 700;
    /* width: 33%; */
    flex-shrink: 0;
    line-height: 1;
    text-align: center;
    color: #003544;
    margin: 0;
    box-sizing: border-box;
}

.card_logo {
    padding: 0 20px;
    flex-grow: 1;
    font-size: 20px;
    font-weight: 600;
    /* font-size: 40px;
    font-weight: 700; */
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
    }
    
}

.imageuploadinner_card_logo {
    position: relative;

    @media (min-width: 800px) {
      width: 200px;
      height: 200px;
    }

    width: 150px;
    height: 150px;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        border-radius: 24px!important;
        object-fit: contain;
      }
}



.visit_2_btn {
    width: 100%;
    height: 49px;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 1rem 1.5rem;
    color: #fff;
    background-color: #e3800d;
    border: transparent;
    transition: background-color .5s cubic-bezier(.37,.01,0,.98);
    /* display: inline-flex; */
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    /* border-width: 1px; */
    /* border-style: solid; */
    border-radius: .5rem;
    margin: 0;
    box-sizing: border-box;
    cursor: pointer;

    @media (min-width: 800px) {
        display: none;
    }
}




.card_sub_div_2 {
    padding: 16px;
    display: flex;
    overflow: hidden;
    background-color: #f5f6f8;
    border-radius: 8px;
    flex-direction: row-reverse;
    grid-gap: 0 20px;
    gap: 0 20px;
    display: flex;
    align-items: center;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        flex-direction: column;
        grid-row-gap: 16px;
        row-gap: 16px;
    }
}

.rating_div {
    width: 33%;
    padding: 8px;
    flex-direction: column;
    grid-gap: 4px 0;
    gap: 4px 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* column-gap: 16px; */
    line-height: 1;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    color: #003544;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 100%;
        flex-direction: row;
        grid-column-gap: 16px;
        -webkit-column-gap: 16px;
                column-gap: 16px;
    }
}

.rating_txt_01 {
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    margin: 0;
    box-sizing: border-box;
}

.rating_txt_02 {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin: 0;
    box-sizing: border-box;
}

.rating_txt_03 {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    box-sizing: border-box;
}

.p_description_div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 100%;
    }
}

.p_description_sub_div {
    display: flex;
    grid-column-gap: 11px;
    -webkit-column-gap: 11px;
            column-gap: 11px;
    margin: 0;
    box-sizing: border-box;
    align-items: center;
}

.p_description_text {
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    line-height: 16px;
    color: #003544;
    margin: 0;
    box-sizing: border-box;
}

.badge_div {
    margin: 6px 16px 0;
    display: flex;
    align-items: center;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    color: #fff; 
}

.badge_svg {
    width: 20px;
    height: 20px;
    stroke: currentcolor;
}

.badge_txt {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 700;
}
#root {
    /* height: 100%;
    overflow-x: hidden;
    margin: 0; */
    background-color: #fff;
}
