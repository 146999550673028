.sub_03_container_wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);

    margin: 0;
    border: 0;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    width: 100%;
}

.sub_03_container {
    margin: 0 auto;
    border: 0;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    width: 100%;

    max-width: 1550px;
    padding: 0 90px;

    @media (max-width: 800px) {
        padding: 0px
    }
}

.sub_03_row_bg_wrap {
    margin: 0;
    padding: 0;
    border: 0;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;
}

.sub_03_inner_wrapper {
    margin: 0;
    padding: 0;
    border: 0;

    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    box-sizing: border-box;

}

.sub_03_row_bg_viewport {
    margin: 0;
    padding: 0;
    border: 0;

    background-image: url('assets/headless_cms_scaled_02.jpg');
    background-position: left top;
    background-repeat: no-repeat;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;

    box-sizing: border-box;
}

.sub_03_bg_overlay {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #000000;
    opacity: 0.3;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    z-index: 3;
    backface-visibility: hidden;
    box-sizing: border-box;

}

.sub_03_row_col_12_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0!important;
    color: #fff;
    float: none;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
    width: auto;
    position: relative;
    box-sizing: border-box;
}

.sub_03_vc_col_sm_12 {
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;    
    box-shadow: none;
    background-position: center;
    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;
    color: #fff;
}

.sub_03_wpb_text_top {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em;
    box-sizing: border-box;
    color: #fff;
}

.sub_03_wpb_text_top h2 {
    margin: 0;
    padding: 0;
    border: 0;
    color: #fff;
    font-family: 'RobotoCondensedRegular';
    letter-spacing: -1px;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;

    @media (max-width: 800px) {
        font-size: 35px;
        line-height: 42px;
    }
}

.sub_03_wpb_text_top p {

    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 1.5em;

    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.1px;
}

.sub_03_nectar_fancy_ul {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 24px;
    box-sizing: border-box;

}

.sub_03_nectar_fancy_ul ul {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 30px;
    box-sizing: border-box;

}

.sub_03_nectar_fancy_ul ul li {
    margin: 0;
    padding: 0;
    border: 0;

    font-weight: 400;
    list-style: none;
    position: relative;
    padding-left: 38px;

}

.sub_03_nectar_fancy_ul ul li i {
    margin: 0;
    padding: 0;
    border: 0;

    line-height: 32px;
    font-size: 16px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    color: #ff5433;
    font-style: normal;
    background-color: transparent;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    background-image: none;
    background-position: 0% 0%;
    background-repeat: repeat;
    margin-top: 0;

}

.sub_03_nectar_fancy_ul ul li span {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 400;
    box-sizing: border-box;
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 32px;
}

.sub_01_wpb_raw_code_grid {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
}

.sub_01_btn_live_div {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
    color: #fff;
    margin-bottom: 24px;
}

.sub_01_download_btn {
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FF5532;
    color: #fff;
    font-size: 20px;
    border-radius: 25px;
    padding: 0 33px;
    font-weight: bold;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    margin: 0;
    text-decoration: none;
    border-radius: 4px;

    box-shadow: none;
    box-sizing: border-box;

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), transform .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1);
}


.sub_01_download_btn:hover {
    background-color: #ff2e03;
}