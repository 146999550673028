.sub_01_cubeshape {
	text-align: center;
	margin: 0 auto;
}

.sub_01_cubeshape h1 {
	margin: 0;
    padding: 0;
    font-size: 16px;
}


.sub_01_cubeshape h1 a {
	
}


.sub_01_cubeshape span {
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.sub_01_cube {
    -webkit-transition: -webkit-transform .33s;
    transition: transform .2s; /* Animate the transform properties */

    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; 
}

.sub_01_first_cube {
    background-color: transparent;
    border: 4px solid white;
    -webkit-transform: translateZ(30px);
    transform: translateZ(30px);
}

.sub_01_second_cube {
    background-color: white;
    color: black;
    border: 4px solid white;
    margin-top: -2px;
    -webkit-transform: rotateX(-90deg) translateZ(-30px);
    transform: rotateX(-90deg) translateZ(-30px); 

    @media (max-width: 800px) {
        display: none;
    }
}

.sub_01_first_cube span {
    cursor: pointer;
    color: white;
    padding: 14px;
    box-sizing: border-box;
}

.sub_01_second_cube span {
    cursor: pointer;
    color: #725b05;
    padding: 16px;
    box-sizing: border-box;
}

.sub_01_cube:hover {
    @media (min-width: 800px) {
        -webkit-transform: rotateX(89deg);
        transform: rotateX(89deg); 
    }
    
}