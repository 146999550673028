@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div_m a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root2 {
    @media (min-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    background-color: #fff;
}
.total_container_m {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div_m {
    flex: 1 0 auto;
}
.foot_img_div_m {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img_m {
    height: 149px;
    width: 100%;
    background-image: url("assets/houses2.webp");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links_m {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container_m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container_m a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div_m {
  padding: 15px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result_m {
    padding: 15px 0px;
}
.content_head_m {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result_m {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div_m {
    cursor: pointer;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div_m:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div_m {
    width: 115px;
}
.content_quiz_div_m {
    /* max-width: 1200px; */
    padding: 0 5px;
    margin: 0 auto;
}
.result_div_font_m {
    font-family: "Roboto";
}
.quiz_div_fadeOut_m {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn_m {
  opacity: 1;
  transition: opacity 1.5s;
}
.content_quiz_container_m {
    margin-top: 30px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 5px;
}
.quiz_question_div_m {
    font-size: 25px!important;
    line-height: 25px!important;
    /* margin-bottom: 50px; */
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container_m {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.quiz_answers_button_div_m {
        display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* border-radius: 10px; */
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    /* min-height: 190px; */
    transition: transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
    width: 85%;
    padding: 0 0 0 15px;
    margin-bottom: 15px;
}
.quiz_answers_button_div_m:hover {
    /* transform: translate(0px, -5%); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span_m {
    background-image: url("assets/yes.svg");
    background-size: 70%;
    /* margin: 20px auto 0px; */
    display: block;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span_m {
    min-height: 45px;
    margin: 5px;
    line-height: 22px;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    /* text-align: center; */
    text-align: left;
    display: flex;
    align-items: center;
}