.sub_01_wpb_row {
    margin: 0;
    padding: 0;
}

.sub_01_row_col_wrap_12 {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    float: none;
    z-index: 12;
    width: auto;
    border: 0;
    position: relative;
    box-sizing: border-box;
}

.sub_01_vc_col_sm_12 {
    display: flex;
    margin: 0;
    padding: 0;
    float: none;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    position: relative;
    box-sizing: border-box;
}

.sub_01_vc_column_inner {
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: calc(100vw * 0.05);
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
}

.sub_01_column_image_bg_wrap {
    opacity: 1;
    transform: scale(1);
    transition: transform 1.3s cubic-bezier(.12,.75,.4,1), opacity .55s ease-out .2s;
    z-index: -1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_01_inner_wrap {
    transform: scale(1);
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transition: transform 1.3s cubic-bezier(.12,.75,.4,1);
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.cub_01_column_image_bg {
    /* background-image: url('assets/headliess_cms2_scaled.jpg'); */
    background-image: url('assets/headliess_cms2_scaled_02.jpg');
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

.sub_01_column_bg_overlay {
    overflow: hidden;
    opacity: 1;
    transform: scale(1);
    transition: background-color .45s cubic-bezier(.25,1,.33,1), opacity .35s ease-out, transform 1.3s cubic-bezier(.12,.75,.4,1);
    pointer-events: none;
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: ' ';
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_01_column_overlay_layer {
    background-color: rgba(10, 10, 10, 0.59);
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: ' ';
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_wpb_wrapper {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_vc_column_inner_grid {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_wpb_wrapper_grid {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_01_img_with_animation_wrap {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 25px;
    margin-left: 6px;
    line-height: 0;
    color: #fff;
}

.sub_01_inner_grid {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.sub_01_hover_wrap_inner {
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    color: #fff;

}

.sub_01_img_with_animation_img {
    opacity: 1;
    transform: none;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    box-shadow: none;
    max-width: 100%;
    height: auto;
    position: relative;
    box-sizing: border-box;

}

.sub_01_nectar_split_heading_grid {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 24px;
    box-sizing: border-box;
}

.sub_01_nectar_heading_h1 {
    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: 0;
    color: #fff;
    font-family: "RobotoCondensedSemiBold";
    letter-spacing: -1px;
    font-size: 82px;
    line-height: 0;
    font-weight: 700;

    @media (max-width: 800px) {
        font-size: 54px;
    }
}

.sub_01_span_inner {
    transform: translate(0px, 0px); 
    opacity: 1;
    position: relative;
    display: inline-block;
    line-height: 1.2;
    vertical-align: bottom;
    box-sizing: border-box;
}

.sub_01_span_outter {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1.2;
    vertical-align: bottom;
    position: relative;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
}

.sub_01_nectar_3d_flip_button_grid {
    margin: 0;
    padding: 0;
    border: 0;

    margin-right: 45px;
    /* width: 150.539px;
    height: 54px; */
    visibility: visible;

    position: relative;
    margin-bottom: 0;

    display: inline-block;

    font-weight: 700;
    font-size: 12px;
    line-height: 20px;

    font-family: 'Roboto';

    box-sizing: border-box;

    @media (max-width: 800px) {
        margin-bottom: 20px;
    }

}

.sub_01_wpb_text_column {
    margin: 0;
    padding: 0;
    border: 0;

    margin-bottom: 1.5em;

    padding-top: 20px!important;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding-top: 0px!important;
    }

}

.sub_01_wpb_wrapper_text_column {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
}

.sub_01_wpb_wrapper_text_column p {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;

    padding-bottom: 1.5em;
    color: #fff;
}

.sub_01_wpb_wrapper_text_column span {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
    color: #fff;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.1px;
}

.sub_01_wpb_raw_code_grid {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
}

.sub_01_btn_live_div {
    margin: 0;
    padding: 0;
    border: 0;

    box-sizing: border-box;
    color: #fff;
    margin-bottom: 24px;
}

.sub_01_btn_live_div button {
    /* border-radius: 5px!important; */
}

.sub_01_download_btn {
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FF5532;
    color: #fff;
    font-size: 20px;
    border-radius: 25px;
    padding: 0 33px;
    font-weight: bold;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    margin: 0;
    text-decoration: none;
    border-radius: 4px;

    box-shadow: none;
    box-sizing: border-box;

    transition: opacity .45s cubic-bezier(0.25,1,0.33,1), transform .45s cubic-bezier(0.25,1,0.33,1), border-color .45s cubic-bezier(0.25,1,0.33,1), color .45s cubic-bezier(0.25,1,0.33,1), background-color .45s cubic-bezier(0.25,1,0.33,1), box-shadow .45s cubic-bezier(0.25,1,0.33,1);
}


.sub_01_download_btn:hover {
    background-color: #ff2e03;
}