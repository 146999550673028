.sub_06_container_wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: calc(100vw * 0.05);
    padding-left: calc(100vw * 0.05);
}

.sub_06_whats_inside {
    margin: 0 auto;
    border: 0;

    max-width: 1550px;
    padding: 0 90px;
    

    /* margin-top: 100px; */
    margin-bottom: 40px;

    position: relative;

    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0;
    }


} 

.sub_06_wpb_row_vc_fluid {
    margin: 0;
    padding: 0;
    border: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 200px;
    /* margin-top: 200px; */
    position: relative;
    box-sizing: border-box;
}

.sub_06_vc_column_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
}

.sub_06_inner {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

}

.sub_06_hover_wrap_inner {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    transition: transform .65s cubic-bezier(.05,.2,.1,1);
}

.sub_06_hover_wrap_inner img {
    margin: 0;
    padding: 0;
    border: 0;

    box-shadow: none;
    
    opacity: 1;
    transform: none;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    max-width: 100%;
    height: auto;
    position: relative;
}

.sub_06_data_tf_live {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-bottom: 24px;

}

.sub_06_content_form_div {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    padding-inline: 80px;
    text-align: start;

    @media (max-width: 800px) {
        padding-inline: 40px;
    }

}

.sub_06_root_sc_sc {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    width: 100%;
    max-width: 720px;
    margin: 0px auto;
    padding-inline: 0px;
}

.sub_06_question_heading {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-top: 32px;

    position: relative;
    -webkit-font-smoothing: antialiased;
    display: flex;
    overflow-wrap: break-word;

    text-align: start;

    @media (max-width: 800px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

}

.sub_06_question_heading span {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin: 0px;
    max-width: 100%;
    width: inherit;
    font-family: 'Roboto';
    font-size: 24px;
    line-height: 32px;
    color: rgb(0, 0, 0);
    text-align: start;

    @media (max-width: 800px) {
        font-size: 20px;
        line-height: 28px;
    }
}

.sub_06_spacewrapper {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    margin-block-start: 8px;
    text-align: start;
}





.rvp_pl_5_mr_1 {
    /* padding-left: 1.25rem; */
    box-sizing: border-box;
    /* width: 49%; */
    margin-bottom: 48px;

    @media (max-width: 800px) {
        margin-bottom: 25px;
    }
}

.rvp_field_wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding-right: 0;

    }
}

.rvp_flex_col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_col_label_div {
    display: flex;
    width: 100%;
    font-weight: 500;
    margin-bottom: 9px;
    color: #2b62bb;

    font-size: 16px;
    font-family: 'Roboto';
    line-height: 24px;

    @media (max-width: 800px) {
        font-size: 14px;
        line-height: 22px;
    }
}

.rvp_input_field_div {
    position: relative;
    width: 100%;
    margin-bottom: 9px;
}

.rvp_input_field_text {
    border-width: 1px;
    border-color: rgb(179 199 231);
    background-color: white;
    color: #0d4cb2;
    font-size: 24px;
    line-height: 24px;
    min-height: 2.375rem;
    touch-action: manipulation;
    width: 100%;
    box-sizing: border-box;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;

    @media (max-width: 800px) {
        font-size: 20px;
        line-height: 20px;
    }
}

.rvp_input_field_text:focus-visible {
    border-width: 2px;
    border-color: rgb(20, 106, 243);
    /* background: rgb(255, 255, 255); */
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    outline: none;
}

::placeholder {
    color: #b4c7e7;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12-18 */
    color: #b4c7e7;
  }

  .sub_06_submit_btn_div {
    position: relative;
  }

  .sub_06_btn_container {
    margin-top: 16px;
  }

  .sub_06_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 auto;

  }

  .sub_06_btn_wrapper_div {
    display: flex;
    flex-flow: wrap;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    margin-block-end: 0px;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 800px) {
        width: 100%;
    }
  }

  .sub_06_btn_div {
    position: relative;
    font-family: inherit;
    line-height: initial;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    outline: none;
    border: 1px solid transparent;
    margin: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
    /* padding-block: 6px; */
    padding-inline: 14px;
    min-height: 40px;
    background-color: rgb(255 85 50);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    width: 100%;
    text-transform: none;
    display: flex;
  }
  

  .sub_06_btn_div:hover {
    background-color: rgb(245, 120, 94);
  }

  .sub_06_btn_text {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;

    @media (max-width: 800px) {
        font-size: 16px;
        line-height: 16px;
    }
  }



