.card_div {
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .05), 0 5px 15px 0 rgba(0, 0, 0, .08);
    border-radius: 16px;
    margin: 0;
    box-sizing: border-box;
}

.card_sub_div_1 {
    display: flex;
    align-items: center;
    margin: 0;
    box-sizing: border-box;
}

.rank_num {
    width: 16%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 40px;
    font-weight: 700;
    /* width: 33%; */
    flex-shrink: 0;
    line-height: 1;
    text-align: center;
    color: #003544;
    margin: 0;
    box-sizing: border-box;
}

.card_logo {
    padding: 0 20px;
    flex-grow: 1;
    font-size: 20px;
    font-weight: 600;
    /* font-size: 40px;
    font-weight: 700; */
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
    }
    
}

.imageuploadinner_card_logo {
    position: relative;

    @media (min-width: 800px) {
      width: 200px;
      height: 200px;
    }

    width: 150px;
    height: 150px;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        border-radius: 24px!important;
        object-fit: contain;
      }
}

.visit_btn {
    width: 33%;
    height: 49px;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 1rem 1.5rem;
    color: #fff;
    background-color: #e3800d;
    border: transparent;
    transition: background-color .5s cubic-bezier(.37,.01,0,.98);
    /* display: inline-flex; */
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    /* border-width: 1px; */
    /* border-style: solid; */
    border-radius: .5rem;
    margin: 0;
    box-sizing: border-box;
    cursor: pointer;

    @media (max-width: 800px) {
        display: none;
    }
}


.visit_btn:hover {
    background-color: #f18d17;
}












.card_sub_div_2 {
    padding: 16px;
    display: flex;
    overflow: hidden;
    background-color: #f5f6f8;
    border-radius: 8px;
    flex-direction: row-reverse;
    gap: 0 20px;
    display: flex;
    align-items: center;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        flex-direction: column;
        row-gap: 16px;
    }
}

.rating_div {
    width: 33%;
    padding: 8px;
    flex-direction: column;
    gap: 4px 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* column-gap: 16px; */
    line-height: 1;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    color: #003544;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 100%;
        flex-direction: row;
        column-gap: 16px;
    }
}

.rating_txt_01 {
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    margin: 0;
    box-sizing: border-box;
}

.rating_txt_02 {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin: 0;
    box-sizing: border-box;
}

.rating_txt_03 {
    font-family: var(--font-roboto-slab);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    box-sizing: border-box;
}

.p_description_div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 100%;
    }
}

.p_description_sub_div {
    display: flex;
    column-gap: 11px;
    margin: 0;
    box-sizing: border-box;
    align-items: center;
}

.p_description_text {
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    line-height: 16px;
    color: #003544;
    margin: 0;
    box-sizing: border-box;
}

.badge_div {
    margin: 6px 16px 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #fff; 
}

.badge_svg {
    width: 20px;
    height: 20px;
    stroke: currentcolor;
}

.badge_txt {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 700;
}